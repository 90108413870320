import { Fragment } from 'react';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import Faktur from '../../backend/models/Faktur';

interface IProps {
  pageAttributes: {
    name?: string
    dataCount: number
    totalDpp: number
    totalPpn: number
    totalNew: number
  }
  queryAttributes: {
    dateStart?: Date
    dateEnd?: Date
    companyName?: string
    exact?: boolean
  }
  data: Faktur[]
}

const styles = StyleSheet.create({
  root: {
    fontFamily: "Times-Roman",
    fontSize: "12pt",
  },
  page: {
    paddingVertical: "2cm",
    paddingRight: "2cm",
    paddingLeft: "2cm",
    fontSize: "12pt",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  headerImg: {
    width: "60px",
    aspectRatio: "1/1"
  },
  headerHighlight: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    gap: "4px"
  },
});


export function ReportPDFLayout(props: IProps) {
  return (
    <Document
      title={props.pageAttributes.name??`tekad-faktur-pajak-app-reporting-${new Date().toString()}`}
      author={"tekad-faktur-pajak-app"}
      style={styles.root}
    >
      <Page size={"A4"} style={styles.page}>
        <View fixed style={styles.header}>
          <Image src={"/images/tekad-logo.png"} style={styles.headerImg} />
          <View style={styles.headerHighlight}>
            <Text
              style={{
                fontSize: "14px", fontWeight: "bold", textAlign: "right",
              }}
            >
              {"LAPORAN TEKAD FAKTUR PAJAK"}
            </Text>
            <Text style={{ fontSize: "12px", textAlign: "right", }}>{new Date().toDateString()}</Text>
          </View>
        </View>
        <hr />
        <View style={{ marginBottom: "8px" }}>
          <Text fixed
            style={{
              textAlign: "right", marginBottom: "8px",
            }}
          >
            {`Total Data: ${props.pageAttributes.dataCount}`}
          </Text>

          <View
            style={{
              display: "flex", flexDirection: "column", gap: "8px",
            }}
          >
            { props.data.map((item, index) => {
              return (
                <Fragment key={`reportpdf-data-complete-${index}`}>
                  { index === 0 ? (
                    <View
                      key={`reportpdf-data-header`}
                      style={{
                        display: "flex", flexDirection: "row", gap: "8px",
                        alignItems: "flex-start", justifyContent: "space-between",
                        borderBottom: "2px solid black"
                      }}
                    >
                      <Text style={{ minWidth: "20px", borderRight: "1px solid black", fontWeight: "bold" }}>{"No."}</Text>
                      <Text style={{ borderRight: "1px solid black", flex: 1, fontWeight: "bold" }}>{"Nama"}</Text>
                      <Text style={{ flex: 1, fontWeight: "bold" }}>{"NPWP"}</Text>
                      <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", fontWeight: "bold" }}>{"Kode"}</Text>
                      {/* <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", fontWeight: "bold" }}>{"Alamat"}</Text> */}
                      <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", fontWeight: "bold" }}>{"DPP (Rp)"}</Text>
                      <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", fontWeight: "bold" }}>{"PPN (Rp)"}</Text>
                    </View>
                  ) : null }
                  <View
                    key={`reportpdf-data-${index}`}
                    style={{
                      display: "flex", flexDirection: "row", gap: "8px",
                      alignItems: "flex-start", justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ minWidth: "20px", borderRight: "1px solid black", fontWeight: "bold" }}>{index+1}</Text>
                    <Text style={{ borderRight: "1px solid black", flex: 1 }}>{item.companyName}</Text>
                    <Text style={{ flex: 1 }}>{item.companyNpwp}</Text>
                    <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", }}>{item.code}</Text>
                    {/* <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", }}>{item.companyAddress}</Text> */}
                    <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", }}>
                      {item.dpp.toLocaleString()}
                      {/* <MyNumberFormat value={item.dpp} /> */}
                    </Text>
                    <Text style={{ borderLeft: "1px solid black", flex: 1, textAlign: "right", }}>
                      {item.ppn.toLocaleString()}
                      {/* <MyNumberFormat value={item.ppn} /> */}
                    </Text>
                  </View>
                  { index === props.data.length - 1 ? (
                    <View
                      key={`reportpdf-data-footer`}
                      style={{
                        display: "flex", flexDirection: "column", gap: "8px",
                        alignItems: "flex-end", justifyContent: "flex-start",
                        marginTop: "8px",
                      }}
                    >
                      <Text>{`TOTAL DPP: Rp ${props.pageAttributes.totalDpp.toLocaleString()}`}</Text>
                      <Text>{`TOTAL PPN: Rp ${props.pageAttributes.totalPpn.toLocaleString()}`}</Text>
                      <Text>{`TOTAL NEW: Rp ${props.pageAttributes.totalNew.toLocaleString()}`}</Text>
                    </View>
                  ) : null }
                </Fragment>
              )
            }) }
          </View>
        </View>
        {/* <hr /> */}
        {/* <View fixed
          style={{
            display: "flex", flexDirection: "row", gap: "4px",
            alignItems: "center", justifyContent: "flex-end",
          }}
        >
          <Text>{`TOTAL DPP: Rp ${props.pageAttributes.totalDpp.toLocaleString()}`}</Text>
          <Text>{`TOTAL PPN: Rp ${props.pageAttributes.totalPpn.toLocaleString()}`}</Text>
          <Text>{`TOTAL NEW: Rp ${props.pageAttributes.totalNew.toLocaleString()}`}</Text>
        </View> */}
      </Page>
    </Document>
  )
}