import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import SaveAltIcon from '@mui/icons-material/SaveAlt';

import AdminLayout from "../../layouts/admin/Layout";
import MyBreadCrumbs, { IMyBreadCrumbs } from "../../components/MyBreadCrumbs";

import { useDialog } from "../../hooks/dialogHook";
import MyNumberFormat from "../../utils/numberFormater";

import Faktur from "../../backend/models/Faktur";

const B_Items: IMyBreadCrumbs[] = [
  { label: "Admin", path: "/admin" },
  { label: "Input Faktur" },
]

export default function AdminInputPage() {
  const navigate = useNavigate()
  // const theme = useTheme();
  // const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  // const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { pushAlert, pushConfirm } = useDialog();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [fetchError, setFetchError] = useState<{ message?: string } | null>(null);

  const addFormHook = useForm({
    defaultValues: {
      code: "", companyName: "", companyAddress: "",
      companyNpwp: "", dpp: undefined, ppn: undefined,
      fakturDate: null,
    },
  });
  // const InputIdProps: TextFieldProps = {
  //   label: "ID", fullWidth: true, disabled: true,
  //   value: "eg: random-char-id",
  //   helperText: "ID akan di-generate otomatis saat di simpan.",
  // }
  const InputCodeProps: TextFieldProps = {
    label: "Kode & Nomor Seri", fullWidth: true, required: true,
    placeholder: "eg: 000.000-00.00000000",
    ...addFormHook.register("code", { required: true }),
  }
  const InputCompanyNameProps: TextFieldProps = {
    label: "Nama Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: PT APA SAJA",
    helperText: "UPPERCASE",
    ...addFormHook.register("companyName", { required: true }),
  }
  const InputCompanyAddressProps: TextFieldProps = {
    label: "Alamat Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: JALAN SIDO MUNCUL",
    helperText: "UPPERCASE",
    ...addFormHook.register("companyAddress", { required: true }),
  }
  const InputCompanyNpwpProps: TextFieldProps = {
    label: "NPWP Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: 00.000.000.0-000.000",
    ...addFormHook.register("companyNpwp", { required: true }),
  }
  const InputDppProps: TextFieldProps = {
    label: "Dasar Pengenaan Pajak (DPP)", fullWidth: true, required: true, type: "number",
    placeholder: "eg: 1230000",
    ...addFormHook.register("dpp", { required: true }),
    helperText: !!addFormHook.watch("dpp")?<MyNumberFormat suffix=",-" value={addFormHook.watch("dpp")} />:null,
  }
  const InputPpnProps: TextFieldProps = {
    label: "Total PPN", fullWidth: true, required: true, type: "number",
    placeholder: "eg: 1230000",
    ...addFormHook.register("ppn", { required: true }),
    helperText: !!addFormHook.watch("ppn")?<MyNumberFormat suffix=",-" value={addFormHook.watch("ppn")} />:null,
  }
  const InputFakturDate = {
    label: "Tanggal Faktur", disableFuture: true,
    slotProps: { textField: { required: true } },
    sx: { width: "100%" },
  }

  const addFormHandleSubmit = async (data: any) => {
    setIsLoading(true);
    let newFaktur = new Faktur({...data});
    // console.log(newFaktur)
    await Faktur.add(newFaktur)
    .then(res => {
      pushConfirm({
        title: "Faktur berhasil dibuat!", content: "Apakah anda ingin meninggalkan halaman ini?",
        agreeBtnText: "YA", onAgreeBtnClick: () => navigate("/admin"),
        cancelBtnText: "TIDAK", onCancelBtnClick: () => navigate(0),
      })
    })
    .catch(err => {
      pushAlert({
        title: "Faktur gagal dibuat!", content: String(err.message)
      })
    })
    .finally(() => { setIsLoading(false) })
  }

  return (
    <AdminLayout title={"Input Faktur"} isLoading={isLoading}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <MyBreadCrumbs items={B_Items} maxItems={2} />
          </Stack>
        </Stack>

        {/* { fetchError ? (
          <Alert severity="error">{ fetchError.message ?? "Terjadi sebuah kesalahan." }</Alert>
        ) : null } */}

        <Box component="form" autoComplete="off" onSubmit={addFormHook.handleSubmit(addFormHandleSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={6} lg={5}>
              <Stack spacing={1.5}>
                {/* <TextField {...InputIdProps} /> */}
                <TextField {...InputCompanyNameProps} />
                <TextField {...InputCompanyNpwpProps} />
                <TextField {...InputCompanyAddressProps} />
                <TextField {...InputCodeProps} />
                <TextField {...InputDppProps} />
                <TextField {...InputPpnProps} />
                <Controller
                  control={addFormHook.control} name={"fakturDate"}
                  render={({field}) => (
                    <DatePicker
                      {...InputFakturDate}
                      value={field.value ?? null}
                      onChange={(newValue) => field.onChange(newValue)}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={2} md={3} lg={5}>
              <Alert severity="info">
                {"Input dengan simbol "}
                <strong>UPPERCASE</strong>
                {" akan diubah otomatis ke Huruf Besar!"}
              </Alert>
            </Grid>

            <Grid item xs={12} sm={3} lg={2}>
              <Button
                fullWidth variant="contained"
                type="submit" size="large"
                startIcon={<SaveAltIcon />}
              >
                SIMPAN
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </AdminLayout>
  )
}

// sx: {
//   "-webkit-outer-spin-button": {
//     "-webkit-appearance": "none",
//     margin: 0,
//   },
//   "-webkit-inner-spin-button": {
//     "-webkit-appearance": "none",
//     margin: 0,
//   },
//   "-moz-appearance": "textfield",
// }