// import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAuth } from "../../hooks/auth"
import AdminLayout from "../../layouts/admin/Layout";
import { topFeatures } from "../../mocks/mockDashboard";
// import { autoGenFaktur } from "../../data/dataFaktur";
// import Faktur from "../../backend/models/Faktur";

export default function AdminDashboard() {
  const { userNow } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   const test = async () => {
  //     setIsLoading(true);
  //     let tempArray: Faktur[] = [];
  //     await autoGenFaktur(500, async (data: Faktur) => {
  //       await Faktur.add(data);
  //       // tempArray.push(data)
  //     })
  //     setIsLoading(false)
  //     // console.table(tempArray.filter((_, index) => index < 10))
  //     navigate("/admin/master")
  //   }

  //   test()
  // }, [])

  return (
    <AdminLayout /*title={"Dashboard"} isLoading={isLoading}*/>
      <Typography variant={upMd?"h4":"h6"}>
        {"Welcome, "}
        <Box component="span" sx={{ color: "primary.main" }}>{userNow.displayName??userNow.email}</Box>
        {"!"}
      </Typography>
      {/* <Typography variant="subtitle1" color="GrayText" mb={2}>
        More interesting features are coming soon! Please kindly wait.
      </Typography> */}

      <Typography color="primary.main" variant="h6" mb={1}>
        {"# "}
        <Typography component="span" variant="inherit" fontWeight={400} color="ButtonText">
          {"Main Features"}
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        { topFeatures.map((item, index) => (
          <Grid key={`admin-dashboard-top-features-item-${index}`} item xs={12} md={4}>
            <Card elevation={3} onClick={() => navigate(item.href)}>
              <CardActionArea>
                <CardMedia
                  component="img" height="140" alt={item.imgAlt}
                  image={item.imgSrc}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.cardTitle}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.cardDescription}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )) }
      </Grid>
    </AdminLayout>
  )
}