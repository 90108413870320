import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import AdminLayout from "../../layouts/admin/Layout";
import MyBreadCrumbs, { IMyBreadCrumbs } from "../../components/MyBreadCrumbs"
import BackButton from "../../components/BackButton";
import MyNumberFormat from "../../utils/numberFormater";

import { useDialog } from "../../hooks/dialogHook";
import Faktur from "../../backend/models/Faktur";
import { DatePicker } from "@mui/x-date-pickers";

const B_Items: IMyBreadCrumbs[] = [
  { label: "Admin", path: "/admin" },
  { label: "Master", path: "/admin/master" },
  { label: "Edit Faktur" },
]

export default function AdminEditPage() {
  const { idFaktur } = useParams();
  const navigate = useNavigate();
  // const theme = useTheme();
  // const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { pushAlert, pushConfirm } = useDialog();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [currData, setCurrData] = useState<Faktur|null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);

  const editFormHook = useForm({
    // defaultValues: {
    //   code: "", companyName: "", companyAddress: "",
    //   companyNpwp: "", dpp: undefined, ppn: undefined,
    // },
  });
  const InputCodeProps: TextFieldProps = {
    label: "Kode & Nomor Seri", fullWidth: true, required: true,
    placeholder: "eg: 000.000-00.00000000",
    ...editFormHook.register("code", { required: true }),
  }
  const InputCompanyNameProps: TextFieldProps = {
    label: "Nama Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: PT APA SAJA",
    helperText: "UPPERCASE",
    ...editFormHook.register("companyName", { required: true }),
  }
  const InputCompanyAddressProps: TextFieldProps = {
    label: "Alamat Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: JALAN SIDO MUNCUL",
    helperText: "UPPERCASE",
    ...editFormHook.register("companyAddress", { required: true }),
  }
  const InputCompanyNpwpProps: TextFieldProps = {
    label: "NPWP Perusahaan", fullWidth: true, required: true,
    placeholder: "eg: 00.000.000.0-000.000",
    ...editFormHook.register("companyNpwp", { required: true }),
  }
  const InputDppProps: TextFieldProps = {
    label: "Dasar Pengenaan Pajak (DPP)", fullWidth: true, required: true, type: "number",
    placeholder: "eg: 1230000",
    ...editFormHook.register("dpp", { required: true }),
    helperText: !!editFormHook.watch("dpp")?<MyNumberFormat suffix=",-" value={editFormHook.watch("dpp")} />:null,
  }
  const InputPpnProps: TextFieldProps = {
    label: "Total PPN", fullWidth: true, required: true, type: "number",
    placeholder: "eg: 1230000",
    ...editFormHook.register("ppn", { required: true }),
    helperText: !!editFormHook.watch("ppn")?<MyNumberFormat suffix=",-" value={editFormHook.watch("ppn")} />:null,
  }
  const InputFakturDate = {
    label: "Tanggal Faktur", disableFuture: true,
    slotProps: { textField: { required: true } },
    sx: { width: "100%" },
  }
  const InputCreatedAt = {
    label: "Tanggal Diterima / Dibuat", readOnly: true,
    sx: { width: "100%" },
  }

  const editFormHandleSubmit = async (data: any) => {
    setIsLoading(true);
    let newFaktur = new Faktur({...data, id: idFaktur});
    // console.log(newFaktur)
    await Faktur.update(newFaktur)
    .then(res => {
      pushConfirm({
        title: "Faktur berhasil disimpan!", content: "Apakah anda ingin meninggalkan halaman ini?",
        agreeBtnText: "YA", onAgreeBtnClick: () => navigate("/admin/master"),
        cancelBtnText: "TIDAK", onCancelBtnClick: () => navigate(0),
      })
    })
    .catch(err => {
      pushAlert({ title: "Faktur gagal disimpan!", content: String(err.message) })
    })
    .finally(() => { setIsLoading(false) })
  }
  const handleDeleteClick = async () => {
    pushConfirm({
      title: "Hapus Faktur", content: "Apakah anda yakin untuk menghapus?",
      agreeBtnText: "YA, SAYA YAKIN",
      onAgreeBtnClick: async () => {
        setIsLoading(true);
        await Faktur.delete(String(idFaktur))
        .then(() => {
          pushAlert({
            title: "Berhasil!", content: "Faktur berhasil dihapus!",
            onAgreeBtnClick: () => navigate("/admin/master"),
          })
        })
        .catch(err => {
          pushAlert({ title: "Faktur gagal dihapus!", content: String(err.message) });
        })
        .finally(() => { setIsLoading(false) })
      }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await Faktur.findById(String(idFaktur))
      .then((res) => {
        // console.log(res);
        editFormHook.setValue("code", res.code);
        editFormHook.setValue("companyName", res.companyName);
        editFormHook.setValue("companyAddress", res.companyAddress);
        editFormHook.setValue("companyNpwp", res.companyNpwp);
        editFormHook.setValue("dpp", res.dpp);
        editFormHook.setValue("ppn", res.ppn);
        editFormHook.setValue("keywords", res.keywords);
        editFormHook.setValue("fakturDate", res.fakturDate);
        editFormHook.setValue("createdAt", res.createdAt);
        // setCurrData(res);
      })
      .catch(err => { setNotFound(true); })
      .finally(() => { setIsLoading(false) })
    }
    
    fetchData();
  }, [idFaktur, editFormHook])

  if (notFound) return <Navigate to="/404" />
  return (
    <AdminLayout title="Edit Faktur" isLoading={isLoading}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <BackButton />
            <MyBreadCrumbs items={B_Items} maxItems={2} />
          </Stack>
        </Stack>

        { isLoading ? null : (
          <Box component="form" autoComplete="off" onSubmit={editFormHook.handleSubmit(editFormHandleSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7} md={6} lg={5}>
                <Stack spacing={2}>
                  <TextField {...InputCompanyNameProps} />
                  <TextField {...InputCompanyNpwpProps} />
                  <TextField {...InputCompanyAddressProps} />
                  <TextField {...InputCodeProps} />
                  <TextField {...InputDppProps} />
                  <TextField {...InputPpnProps} />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={2} md={3} lg={5}>
                <Stack spacing={2}>
                  <Alert severity="info">
                    {"Input dengan simbol "}
                    <strong>UPPERCASE</strong>
                    {" akan diubah otomatis ke Huruf Besar!"}
                  </Alert>
                  <Controller
                    control={editFormHook.control} name={"fakturDate"}
                    render={({field}) => (
                      <DatePicker
                        {...InputFakturDate}
                        value={field.value ?? null}
                        onChange={(newValue) => field.onChange(newValue)}
                      />
                    )}
                  />
                  <Controller
                    control={editFormHook.control} name={"createdAt"}
                    render={({field}) => (
                      <DatePicker
                        {...InputCreatedAt}
                        value={field.value ?? null}
                        // onChange={(newValue) => field.onChange(newValue)}
                      />
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3} lg={2}>
                <Box>
                  <Stack direction="column" spacing={1.5}>
                    <Button
                      fullWidth variant="contained"
                      type="submit" size="large"
                      startIcon={<SaveAltIcon />}
                    >
                      SIMPAN
                    </Button>
                    <Button
                      fullWidth variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={handleDeleteClick}
                    >
                      HAPUS
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
    </AdminLayout>
  )
}