import { Fragment, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAuth } from "../../hooks/auth";
import { LoadingButton } from "@mui/lab";

export default function ForgotPasswordPage() {
  const [params] = useSearchParams();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { ResetPassword } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordReseted, setPasswordReseted] = useState<boolean>(false);

  const forgotPasswordFormHook = useForm({
    defaultValues: {
      email: params.get("e")??"",
    }
  });

  const InputEmailProps: TextFieldProps = {
    label: "Email", fullWidth: true, required: true, type: "email",
    helperText: "Dengan begini, anda akan mengirim email untuk mengatur ulang kata sandi anda. Pastikan email yang terisi di atas sudah benar.",
    ...forgotPasswordFormHook.register("email"),
  }

  const forgotPasswordSubmit = async (data: any) => {
    setIsLoading(true)
    await ResetPassword(String(data?.email), `${window.location.origin}/auth/login`)
    .then(res => { setPasswordReseted(true) })
    .catch(err => {})
    .finally(() => { setIsLoading(false) })
  }

  return (
    <Stack
      direction="column" alignItems="center" justifyContent="center"
      component="form" onSubmit={forgotPasswordFormHook.handleSubmit(forgotPasswordSubmit)}
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: theme.palette.primary.main,
        padding: 3,
      }}
    >
      <Box
        component="img"
        src="/images/tekad-logo.png"
        onClick={() => navigate("/")}
        sx={{ 
          aspectRatio: "1/1", width: upMd?180:120,
          cursor: "pointer",
          mb: 2,
        }}
      />
      <Typography
        color="white" fontSize={"2rem"} fontWeight={400}
        align="center"
        mb={1.5}
      >
        {"Atur Ulang Password"}
      </Typography>

      <Card
        elevation={10}
        sx={{
          width:"100%", maxWidth: 400,
          bgcolor: "rgba(255, 255, 255, 0.976)",
        }}
      >
        { passwordReseted ? (
          <CardContent>
            <Typography align="justify">{"Sebuah email telah terkirim ke email anda, silahkan melakukan pengecekan."}</Typography>
            <Typography align="justify">{"Jika tidak ditemukan, mohon untuk mencoba cari pada bagian spam juga."}</Typography>
            <br />
            <Typography align="justify" fontWeight={600}>{"Terimakasih!"}</Typography>
          </CardContent>
        ) : (
          <Fragment>
            <CardContent>
              <Stack
                direction="column" alignItems="center" justifyContent="center"
                spacing={2}
              >
                <TextField {...InputEmailProps} />
              </Stack>
            </CardContent>
            <CardActions>
               <LoadingButton
                loading={isLoading} type="submit" variant="contained" fullWidth
              >
                {"Kirim"}
              </LoadingButton>
             </CardActions>
          </Fragment>
        ) }
      </Card>
    </Stack>
    // <Box sx={{ width: "100vw", minHeight: "100vh", overflow: "hidden", bgcolor: "secondary.main" }}>
    //   <Box component="form" onSubmit={forgotPasswordFormHook.handleSubmit(forgotPasswordSubmit)} sx={{ minHeight: "100vh" }}>
    //     <Stack justifyContent="center" alignItems="center" sx={{ minHeight: "100vh", py: 6 }}>
    //       { passwordReseted ? (
    //         <Card elevation={3} sx={{ width: "80%" }}>
    //           <CardHeader
    //             title="Atur Ulang Sandi"
    //             titleTypographyProps={{ variant: "h5" }}
    //             sx={{ pb: 1 }}
    //           />
    //           <CardContent>
    //             <Typography align="justify">{"Sebuah email telah terkirim ke email anda, silahkan melakukan pengecekan."}</Typography>
    //             <Typography align="justify">{"Jika tidak ditemukan, mohon untuk mencoba cari pada bagian spam juga."}</Typography>
    //             <br />
    //             <Typography align="justify" fontWeight={600}>{"Terimakasih!"}</Typography>
    //           </CardContent>
    //         </Card>
    //       ) : (
    //         <Card elevation={3} sx={{ width: "80%" }}>
    //           <CardHeader
    //             title="Atur Ulang Sandi"
    //             titleTypographyProps={{ variant: "h5" }}
    //             sx={{ pb: 1 }}
    //           />
    //           <CardContent>
    //             <TextField
    //             />
    //           </CardContent>
    //           <CardActions sx={{ justifyContent: "end" }}>
    //             <LoadingButton loading={isLoading} type="submit" variant="contained" color="secondary">{"Kirim"}</LoadingButton>
    //           </CardActions>
    //         </Card>
    //       ) }
    //     </Stack>
    //   </Box>
    // </Box>
  )
}