import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Box,
  Card,
  CardContent,
  FilledInput,
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// import GoogleIcon from '@mui/icons-material/Google';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { IFirebaseAuthErrorState, authErrorTranslater } from "../../utils/firebaseErrorHelper";
import { stringIncludeArray } from "../../utils/formHelper";
import { useAuth } from "../../hooks/auth";

export default function LoginPage() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { LoginEmail } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputPasswordType, setInputPasswordType] = useState<string>("password");
  const [FirebaseError, setFirebaseError] = useState<IFirebaseAuthErrorState | null>(null);

  const loginFormHook = useForm();
  const emailInputProps = {
    error: stringIncludeArray(FirebaseError?.code ?? "", ["email", "user"]),
    helperText: stringIncludeArray(FirebaseError?.code ?? "", ["email", "user"]) ? FirebaseError?.message : null,
  } as TextFieldProps
  const passwordFormControlProps = {
    error: stringIncludeArray(FirebaseError?.code ?? "", ["password"]),
  } as FormControlProps

  const oneEmpty = (): boolean => {
    return !Boolean(loginFormHook.watch("email")) || !Boolean(loginFormHook.watch("password"))
  }

  const loginFormSubmit = async (data: any) => {
    setIsLoading(true);
    setFirebaseError(null);
    const { error, shouldRefresh } = await LoginEmail(data.email, data.password);
    if (error?.code) setFirebaseError(authErrorTranslater(error.code));
    if (shouldRefresh) navigate(0)
    setIsLoading(false);
  }

  return (
    <Stack
      direction="column" alignItems="center" justifyContent="center"
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: theme.palette.primary.main,
        padding: 3,
      }}
    >
      <Box
        component="img"
        src="/images/tekad-logo.png"
        onClick={() => navigate("/")}
        sx={{ 
          aspectRatio: "1/1", width: upMd?180:120,
          cursor: "pointer",
          mb: 2,
        }}
      />
      <Typography
        color="white" fontSize={"2rem"} fontWeight={400}
        align="center"
        mb={1.5}
      >
        {"Masuk"}
      </Typography>
      <Card
        elevation={10}
        sx={{
          width:"100%", maxWidth: 400,
          bgcolor: "rgba(255, 255, 255, 0.976)",
        }}
      >
        <CardContent>
          <Stack
            component="form" onSubmit={loginFormHook.handleSubmit(loginFormSubmit)}
            direction="column" alignItems="center" justifyContent="center"
            spacing={1.5}
          >
            <TextField
              variant="filled" fullWidth required
              label="Email" type="email"
              {...emailInputProps}
              {...loginFormHook.register("email")}
            />
            <FormControl 
              fullWidth variant="filled" required
              {...passwordFormControlProps}
            >
              <InputLabel htmlFor="input-password">{"Password"}</InputLabel>
              <FilledInput
                id="input-password" type={inputPasswordType} required
                {...loginFormHook.register("password")}
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <IconButton edge="end"
                      onClick={() => setInputPasswordType(Boolean(inputPasswordType === "text") ? "password" : "text")}
                    >
                      {Boolean(inputPasswordType === "password") ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {stringIncludeArray(FirebaseError?.code ?? "", ["password"]) ? FirebaseError?.message : null}
              </FormHelperText>
            </FormControl>

            <Typography
              variant="subtitle1" align="left" fontWeight={500}
              onClick={() => navigate(`/auth/forgot-password?e=${loginFormHook.watch("email")}`)}
              sx={{
                cursor: "pointer", transition: ".3s",
                "&:hover": { color: "primary.main", transform: "scale(1.05)", },
              }}
            >
              {"Lupa kata sandi?"}
            </Typography>

            <LoadingButton
              type="submit" size="large"
              variant={"outlined"} loading={isLoading}
              disabled={oneEmpty()}
              // color={oneEmpty()?"primary":"secondary"}
              sx={{ height: 60, width: 60, }}
            >
              <ArrowForwardIcon />
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}