import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
} from "@mui/x-data-grid";

// import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

import AdminLayout from "../../layouts/admin/Layout";
import MyBreadCrumbs, { IMyBreadCrumbs } from "../../components/MyBreadCrumbs";

import { useDialog } from "../../hooks/dialogHook";
import Faktur from "../../backend/models/Faktur";

const B_Items: IMyBreadCrumbs[] = [
  { label: "Admin", path: "/admin" },
  { label: "Master Faktur" },
]

export default function AdminMasterPage() {
  const navigate = useNavigate();
  const { pushAlert, pushConfirm } = useDialog();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<Faktur[]|null>(null);
  const [lastFetchedData, setLastFetchedData] = useState<Faktur|undefined>(undefined);
  const [fetchError, setFetchError] = useState<{ message?: string } | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  // const normalFetch = async () => {
  //   setIsLoading(true)
  //   setFetchError(null)
  //   await Faktur.all()
  //   .then((response) => {
  //     // console.log(response)
  //     setFetchedData(response)
  //   })
  //   .catch(err => { setFetchError(err); })
  //   .finally(() => setIsLoading(false));
  // }

  const staticFetch = async (count: number) => {
    setIsLoading(true)
    setFetchError(null)
    await Faktur.paginate(count, lastFetchedData?.id)
    .then((response) => {
      // console.log(response)
      let currLastFetchedData = response[response.length-1];
      if (!fetchedData || fetchedData.length <= 0) setFetchedData(response);
      else {
        // let currIslastPage = currLastFetchedData.id===lastFetchedData?.id;
        // if (!currIslastPage)
        setFetchedData([...fetchedData, ...response])
        setIsLastPage(response.length < count)
      }
      setLastFetchedData(currLastFetchedData)
    })
    .catch(err => {
      console.error(err)
      setFetchError(err);
    })
    .finally(() => setIsLoading(false));
  }

  // useEffect(() => {
  //   // normalFetch()
  //   console.log(lastFetchedData)
  //   console.table(fetchedData)
  // }, [fetchedData])

  // const handleDelete = async (row: any) => {
  //   pushConfirm({
  //     title: "Hapus Faktur", content: "Apakah anda yakin untuk menghapus?",
  //     agreeBtnText: "YA, SAYA YAKIN",
  //     onAgreeBtnClick: async () => {
  //       // console.log(row)
  //       await Faktur.delete(String(row.id))
  //         .then(res => {
  //           pushAlert({
  //             title: "Berhasil!", content: "Faktur berhasil dihapus!",
  //             onClose: () => normalFetch(),
  //           });
  //         })
  //         .catch(err => { setFetchError(err); })
  //         .finally(() => setIsLoading(false));
  //     }
  //   });
  // }

  const handleOnRowUpdate = async (updatedRow: Faktur, originalRow: Faktur) => {
    // console.log(updatedRow)
    let newRow: Faktur = originalRow;
    await Faktur.update(updatedRow)
    .then(async (_) => {
      await Faktur.findById(String(updatedRow.id))
        .then(res => {
          newRow = res;
          let rowIndex = fetchedData?.findIndex((value: Faktur) => value.id===res.id);
          let tempResultData = !!fetchedData?[...fetchedData]:null;
          if (!!tempResultData && !!rowIndex) {
            tempResultData[rowIndex] = res;
            setFetchedData(tempResultData);
          }
          pushAlert({title: "Berhasil!", content: "Faktur berhasil diubah!"})
        })
        .catch(err => {throw err});
    })
    .catch(err => {
      pushAlert({title: "Faktur gagal diubah!", content: `${err.message}`})
    })
    return newRow;
  }

  return (
    <AdminLayout title="Master Faktur" isLoading={isLoading}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <MyBreadCrumbs items={B_Items} maxItems={2} />

          <Tooltip title={isLastPage?"Sudah sampai di halaman terakhir":""}>
            <ButtonGroup variant="contained" size="small" disabled={isLoading||isLastPage}>
              <Button onClick={() => staticFetch(10)}>{"Muat 10"}</Button>
              <Button onClick={() => staticFetch(100)}>{"Muat 100"}</Button>
            </ButtonGroup>
          </Tooltip>
        </Stack>

        { fetchError ? (
          <Alert severity="error">{ fetchError.message ?? "Terjadi sebuah kesalahan." }</Alert>
        ) : null }

        { !!fetchedData && fetchedData?.length <= 0 ? (
          <Alert severity="info">{"Masih belum ada data."}</Alert>
        ) : null }

        { !isLoading && !!fetchedData && fetchedData.length ? (
          <Box sx={{ maxWidth: upMd?"count(91.5vw-270px)":"91.5vw" }}>
            <DataGrid
              // sx={{ maxWidth: upMd?"count(91.5vw-270px)":"91.5vw" }}
              slots={{ toolbar: GridToolbar }}
              rows={fetchedData}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              editMode="row"
              processRowUpdate={handleOnRowUpdate}
              // onRowEditStop={(params) => {
              //   console.log("params", params);
              //   // console.log("event", event);
              //   // console.log("details", details);
              // }}
              columns={[
                // { field: "id", type: "string", headerName: "ID", hideable: true },
                { field: "companyName", type: "string", headerName: "Nama Perusahaan", flex: 2, minWidth: 140, editable: true },
                { field: "companyNpwp", type: "string", headerName: "NPWP Perusahaan", align: "right", flex: 1, minWidth: 140, editable: true },
                { field: "companyAddress", type: "string", headerName: "Alamat Perusahaan", align: "right", flex: 1, minWidth: 140, editable: true },
                { field: "code", type: "string", headerName: "Kode & Nomor Resi", align: "right", flex: 1, minWidth: 200, editable: true },
                {
                  field: 'actions', headerName: "Action", type: 'actions', align: "center", width: 80,
                  getActions: (params) => [
                    <GridActionsCellItem
                      color="primary" label="Detail"
                      icon={<InfoIcon />}
                      onClick={() => navigate(`/admin/edit/${params.id}`)}
                    />,
                    // <GridActionsCellItem
                    //   color="error" label="Delete"
                    //   icon={<DeleteIcon />} showInMenu
                    //   onClick={() => handleDelete(params.row)}
                    // />,
                  ],
                },
              ]}
            />
            <Stack direction={"row"} justifyContent="end" sx={{ mt: 4 }}>
              <Tooltip title={isLastPage?"Sudah sampai di halaman terakhir":""}>
                <ButtonGroup variant="contained" size="small" disabled={isLoading||isLastPage}>
                  <Button onClick={() => staticFetch(10)}>{"Muat 10"}</Button>
                  <Button onClick={() => staticFetch(100)}>{"Muat 100"}</Button>
                </ButtonGroup>
              </Tooltip>
            </Stack>
          </Box>
        ) : null }
      </Stack>
    </AdminLayout>
  )
}