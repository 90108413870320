export const topFeatures = [
  {
    imgAlt: "input-data",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/tekad-faktur-pajak.appspot.com/o/assets%2Finput-data.jpg?alt=media&token=e45e7936-40a0-4f7b-8619-fdd58cb8c1f5",
    cardTitle: "Input Faktur",
    cardDescription: `Berfungsi untuk menambahkan data faktur pajak baru`,
    href: "/admin/input",
  },
  {
    imgAlt: "reporting-faktur",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/tekad-faktur-pajak.appspot.com/o/assets%2Freporting.jpg?alt=media&token=b8b1a67b-d1d9-496b-8b97-8fe99b739f90",
    cardTitle: "Reporting Faktur",
    cardDescription: `Berfungsi untuk melakukan query sesuai kebutuhan`,
    href: "/admin/report",
  },
  {
    imgAlt: "master-data",
    imgSrc: "https://firebasestorage.googleapis.com/v0/b/tekad-faktur-pajak.appspot.com/o/assets%2Fmaster-data.jpg?alt=media&token=134bd259-8b29-49cc-9b09-b68cc380e54a",
    cardTitle: "Master Faktur",
    cardDescription: `Berfungsi untuk melihat keseluruhan data faktur dan melakukan beberapa fungsi lainnya seperti menghapus faktur`,
    href: "/admin/master",
  },
]