import { useState, useEffect, Fragment, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  AlertTitle,
} from "@mui/material";
import { DataGrid, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
// import PrintIcon from '@mui/icons-material/Print';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReceiptIcon from '@mui/icons-material/Receipt';

import AdminLayout from "../../layouts/admin/Layout";
import MyBreadCrumbs, { IMyBreadCrumbs } from "../../components/MyBreadCrumbs";

import Faktur from "../../backend/models/Faktur";
import MyNumberFormat from "../../utils/numberFormater";
import { myScrollToRef } from "../../utils/myScrollToRef";
import { ReportPDFLayout } from "../pdf/ReportPDF";
import { useDialog } from "../../hooks/dialogHook";
import ReportPageDppPaymentSection from "../../partials/ReportPageDppPaymentSection";

const B_Items: IMyBreadCrumbs[] = [
  { label: "Admin", path: "/admin" },
  { label: "Reporting Faktur" },
]

interface IReportData {
  pageAttributes: {
    dataCount: number
    totalDpp: number
    totalPpn: number
    totalNew: number
  }
  queryAttributes: {
    dateStart?: Date
    dateEnd?: Date
    companyName?: string
    exact?: boolean
  }
}

export default function AdminReportPage() {
  const { pushConfirm } = useDialog();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<Faktur[]|null>(null);
  const [dataAttributes, setDataAttributes] = useState<IReportData|null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [generatePdf, setGeneratePdf] = useState<boolean>(false);
  const [receiptSectionOpen, setReceiptSectionOpen] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<{ message?: string } | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const tableScrollRef = useRef<HTMLDivElement>(null);
  const receiptScrollRef = useRef<HTMLDivElement>(null);

  const formHook = useForm({
    defaultValues: {
      dateStart: null,
      dateEnd: new Date(),
      dppTreshold: "",
      companyName: "",
      exact: false,
    }
  });

  const formHandleSubmit = async (data: any) => {
    setIsLoading(true);
    setFetchError(null);
    await Faktur.getReport(data.companyName, data.dateEnd, data.dateStart, { exact: data.exact })
    .then(res => {
      setFetchedData(res);
      autoSelectDppPayment(res, data.dppTreshold);
      setDataAttributes({
        pageAttributes: {
          dataCount: res.length,
          totalDpp: res.reduce((total, next) => Number(total + next.dpp), 0),
          totalPpn: res.reduce((total, next) => Number(total + next.ppn), 0),
          totalNew: res.reduce((total, next) => Number(total + next.ppn), 0),
        },
        queryAttributes: {
          dateEnd: data.dateEnd,
          dateStart: data.dateStart,
          companyName: data.companyName,
          exact: data.exact,
        },
      })
      setGeneratePdf(false)
      setReceiptSectionOpen(false)
      // setFetchedData(range({max: 500}).map((item) => {
      //   return new Faktur({
      //     code: "asdasdasdasd",
      //     companyName: "asd asd asd asd",
      //     companyAddress: "asdasd asd asd asdasd asd",
      //     companyNpwp: "asdasdasdasd",
      //     dpp: 1000000,
      //     ppn: 1000000,
      //   })
      // }))
    })
    .catch(err => { 
      // console.log(err)
      setFetchError(err);
     })
    .finally(() => { setIsLoading(false) })
  }

  function autoSelectDppPayment(data: Faktur[], treshold?: number) {
    data = [...data]; // Jaga" sapa tau pass by ref wkwk
    let currTreshold = treshold ?? Number(formHook.watch("dppTreshold"));
    data = data.filter((value) => value.paymentStatus!=="paid").sort((a, b) => a.dpp - b.dpp);
    let storedValidIds: string[] = [];
    data.map((value) => {
      let sisaPengurangan = currTreshold - value.dpp;
      if (sisaPengurangan >= 0) {
        storedValidIds.push(String(value.id));
        currTreshold -= value.dpp;
      }
    })
    setRowSelectionModel(storedValidIds);
  }

  function handlePreparePDFClick() {
    const lengthLimit = 100;
    if (!!fetchedData && fetchedData.length > lengthLimit) {
      pushConfirm({
        title: "Warning!", content: `Anda akan melakukan generate PDF dengan jumlah data lebih dari ${lengthLimit}. Proses ini dapat menyebabkan sedikit lag pada web client anda. Hal ini tidak berbahaya namun mengurangi sedikit rasa nyaman saja.\n\n** Tidak perlu khawatir jika halaman freeze, hal tersebut hanya terjadi sementara saja, harap menunggu dengan sabar`,
        agreeBtnText: "SAYA MENGERTI", onAgreeBtnClick: () => setGeneratePdf(true),
      })
    }
    else setGeneratePdf(true);
  }

  function handleMakeReceipt() {
    if (receiptSectionOpen) {
      myScrollToRef(receiptScrollRef);
    }
    else {
      setReceiptSectionOpen(true);
      window.setTimeout(() => {
        myScrollToRef(receiptScrollRef);
      }, 500)
    }
  }

  useEffect(() => {
    let dateStartWatcher = formHook.watch("dateStart")
    if (dateStartWatcher!==null) {
      let currStart = new Date(dateStartWatcher);
      let currEnd = new Date(formHook.watch("dateEnd"));
      if (currEnd.getTime()<currStart.getTime()) {
        formHook.setValue("dateEnd", currStart);
      }
    }
  }, [formHook.watch("dateStart"), formHook.watch("dateEnd")])

  useEffect(() => {
    if (!isLoading) {
      if (!!fetchedData && fetchedData.length > 0) myScrollToRef(scrollRef);
    }
  }, [isLoading])

  useEffect(() => {
    if (rowSelectionModel.length <= 0) {
      setReceiptSectionOpen(false);
      setReceiptSectionOpen(false);
      myScrollToRef(tableScrollRef);
    }
  }, [rowSelectionModel])

  return (
    <AdminLayout title="Reporting Faktur" isLoading={isLoading}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <MyBreadCrumbs items={B_Items} maxItems={2} />
        </Stack>

        { fetchError ? (
          <Alert severity="error">{ fetchError.message ?? "Terjadi sebuah kesalahan." }</Alert>
        ) : null }

        <Box component="form" onSubmit={formHook.handleSubmit(formHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                control={formHook.control} name="dateStart"
                render={({field}) => (
                  <DatePicker
                    label={"Waktu Mulai"}
                    // slotProps={{ textField: { size: "small" } }}
                    sx={{ width: "100%" }}
                    disableFuture
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue!)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={formHook.control} name="dateEnd"
                render={({field}) => (
                  <DatePicker
                    label={"Waktu Akhir"}
                    // slotProps={{ textField: { size: "small" } }}
                    sx={{ width: "100%" }}
                    disableFuture
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue!)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Treshold DPP" fullWidth size="small" type="number"
                {...formHook.register("dppTreshold")}
                helperText={<MyNumberFormat suffix=",-" value={Number(formHook.watch("dppTreshold"))??0} />}
              />
            </Grid>

            <Grid item xs={9} sm={10} lg={10.5} xl={11}>
              <TextField
                label="Nama Perusahaan" fullWidth
                {...formHook.register("companyName")}
              />
            </Grid>

            <Grid item xs={3} sm={2} lg={1.5} xl={1}>
              <Controller
                control={formHook.control} name="exact" defaultValue={false}
                render={(params) => (
                  <FormControlLabel
                    label="Exact" labelPlacement="end"
                    control={<Checkbox {...params.field} checked={params.field.value} />}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit" fullWidth
                variant="contained"
                startIcon={<PlagiarismIcon />}
              >
                {"Query Laporan"}
              </Button>
            </Grid>
          </Grid>
        </Box>


        { !isLoading && !!fetchedData && !fetchError ? (
          <Stack direction="column" spacing={2} paddingTop={2}>
            { fetchedData.length <= 0 ? (
              <Alert severity="info">{"Tidak ada data dari query tersebut."}</Alert>
            ) : (
              <Fragment>
                <Box ref={scrollRef}>
                  <Typography color="primary.main" variant="h4">
                    {"# "}
                    <Typography component="span" variant="inherit" fontWeight={400} color="ButtonText">
                      {"Data Statistik"}
                    </Typography>
                  </Typography>
                </Box>

                <Box>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12} md={4}>
                      <Alert icon={false} variant="filled" severity="warning">
                        <AlertTitle>{"Total DPP"}</AlertTitle>
                        <Typography variant={"h4"}>
                          <MyNumberFormat value={dataAttributes?.pageAttributes.totalDpp} />
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Alert icon={false} variant="filled" severity="info">
                        <AlertTitle>{"Total PPN"}</AlertTitle>
                        <Typography variant={"h4"}>
                          <MyNumberFormat value={dataAttributes?.pageAttributes.totalPpn} />
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Alert icon={false} variant="filled" severity="success">
                        <AlertTitle>{"Total Rumus"}</AlertTitle>
                        <Typography variant={"h4"}>
                          <MyNumberFormat value={dataAttributes?.pageAttributes.totalNew} />
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <Alert icon={false} variant="outlined" severity="error">
                        <Typography variant="h6" sx={{ color: "error.main" }}>
                          {"Dari total "}
                          <Typography
                            component="span" variant="inherit"
                            sx={{
                              fontWeight: 600, textDecoration: "underline"
                            }}
                          >
                            <MyNumberFormat prefix="" value={dataAttributes?.pageAttributes.dataCount} />
                          </Typography>
                          {" faktur"}
                        </Typography>
                      </Alert>
                    </Grid>
                  </Grid>
                </Box>

                <Box ref={tableScrollRef} pt={2}>
                  <Typography color="primary.main" variant="h4">
                    {"# "}
                    <Typography component="span" variant="inherit" fontWeight={400} color="ButtonText">
                      {"Tabel"}
                    </Typography>
                  </Typography>
                </Box>

                <Paper
                  elevation={3}
                  sx={{
                    overflow: "hidden",
                    maxWidth: "91.2vw",
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"start"} flexWrap={"wrap"}>
                    <Button
                      variant="text" disabled={generatePdf}
                      startIcon={<DriveFileRenameOutlineIcon />}
                      sx={{ borderRadius: 0 }}
                      onClick={handlePreparePDFClick}
                    >
                      {"Persiapkan PDF"}
                    </Button>
                    { generatePdf && !!dataAttributes ? (
                      <PDFDownloadLink
                        fileName={`tekad-faktur-pajak-app-report-${new Date().toTimeString().replace(" ", "-")}`}
                        document={
                          <ReportPDFLayout
                            pageAttributes={{ 
                              dataCount: dataAttributes.pageAttributes.dataCount,
                              totalDpp: dataAttributes.pageAttributes.totalDpp,
                              totalPpn: dataAttributes.pageAttributes.totalPpn,
                              totalNew: dataAttributes.pageAttributes.totalPpn,
                            }}
                            queryAttributes={{
                              companyName: dataAttributes.queryAttributes.companyName,
                              dateEnd: dataAttributes.queryAttributes.dateEnd,
                              dateStart: dataAttributes.queryAttributes.dateStart,
                              exact: dataAttributes.queryAttributes.exact,
                            }}
                            data={fetchedData}
                          />
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          <Button
                            variant="text" size="medium" disabled={loading}
                            startIcon={<PictureAsPdfIcon />}
                            sx={{ borderRadius: 0 }}
                          >
                            {loading?"Memproses PDF":"Unduh PDF"}
                          </Button> 
                        }
                      </PDFDownloadLink>
                    ) : null }

                    <Button
                      variant="text" color="success"
                      startIcon={<ReceiptIcon />}
                      sx={{ borderRadius: 0 }}
                      disabled={!(!!rowSelectionModel && rowSelectionModel.length > 0)}
                      onClick={handleMakeReceipt}
                    >
                      {"Buat Rincian Pembayaran DPP"}
                    </Button>
                  </Stack>
                  
                  <DataGrid
                    sx={{ border: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, }}
                    // slots={{ toolbar: GridToolbar }}
                    rows={fetchedData} density="compact"
                    checkboxSelection={true} disableRowSelectionOnClick={upMd}
                    isRowSelectable={(params: GridRowParams) => params.row.paymentStatus !== "paid"}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    columns={[
                      // { field: "id", type: "string", headerName: "ID", hideable: true },
                      { field: "paymentStatus", type: "boolean", headerName: "DPP Lunas", valueGetter: (row) => row.value==="paid" },
                      { field: "companyName", type: "string", headerName: "Nama Perusahaan", flex: 2, minWidth: 140, editable: false },
                      { field: "companyNpwp", type: "string", headerName: "NPWP Perusahaan", align: "right", flex: 1, minWidth: 140, editable: false },
                      // { field: "companyAddress", type: "string", headerName: "Alamat Perusahaan", align: "right", flex: 1, minWidth: 140, editable: false },
                      { field: "code", type: "string", headerName: "Kode & Nomor Resi", align: "right", flex: 1, minWidth: 200, editable: false },
                      { field: "dpp", type: "number", headerName: "DPP (Rp)", align: "right", flex: 1, minWidth: 200, editable: false },
                      { field: "ppn", type: "number", headerName: "PPN (Rp)", align: "right", flex: 1, minWidth: 200, editable: false },
                    ]}
                  />
                </Paper>

                { receiptSectionOpen ? (
                  <Fragment>
                    <Box ref={receiptScrollRef} pt={2}>
                      <Typography color="primary.main" variant="h4">
                        {"# "}
                        <Typography component="span" variant="inherit" fontWeight={400} color="ButtonText">
                          {"Pembayaran DPP"}
                        </Typography>
                      </Typography>
                    </Box>

                    <ReportPageDppPaymentSection
                      isLoading={isLoading} setIsLoading={setIsLoading}
                      rowSelectionModel={rowSelectionModel}
                      setRowSelectionModel={setRowSelectionModel}
                      fetchedData={fetchedData}
                    />
                  </Fragment>
                ) : null }
              </Fragment>
            ) }
          </Stack>
        ) : null }
      </Stack>
    </AdminLayout>
  )
}