import { Icon } from '@mui/material';

// Middleware
import RoutePublicMiddleware from '../middleware/RoutePublicMiddleware';
import RouteAuthMiddleware from '../middleware/RouteAuthMiddleware';

// Pages
// import Default from "../pages/Default";
// import ReactPDFTest from '../pages/ReactPDFTest';
import Page404 from "../pages/404";

// Auth Pages
import LoginPage from "../pages/auth/LoginPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";

// Admin Pages
import AdminDashboard from '../pages/admin/Dashboard';
import AdminInputPage from '../pages/admin/InputPage';
import AdminReportPage from '../pages/admin/ReportPage';
import AdminMasterPage from '../pages/admin/MasterPage';
import AdminEditPage from '../pages/admin/EditPage';


export interface IRoute {
  index?: boolean
  label?: string
  element?: JSX.Element
  path?: string
  icon?: JSX.Element | any
  children?: IRoute[]
  hideInNav?: boolean
}

export const PUBLIC_ROUTES: IRoute[] = [
  {
    path: "/", element: <RoutePublicMiddleware />,
    children: [
      { index: true, path: "/", element: <LoginPage /> },
      // { path: "/test", element: <Test /> },
    ],
  },
  // {
  //   path: "/forum",
  //   children: [
  //     { index: true, path: "", element: <MainForum /> },
  //     {
  //       path: "c", element: <RouteAuthMiddleware />,
  //       children: [
  //         { index: true, path: "", element: <MainForumCreate /> },
  //       ],
  //     },
  //     { path: "df/:id_thread", element: <MainForumDraft /> },
  //     { path: "d/:id_thread", element: <MainForumDetail /> },
  //   ],
  // },
  {
    path: "/auth", element: <RoutePublicMiddleware />,
    children: [
      { index: true, path: "/auth/login", element: <LoginPage /> },
      { path: "/auth/forgot-password", element: <ForgotPasswordPage /> },
      // { path: "/auth/register", element: <RegisterPage /> },
    ],
  },
]

export const ADMIN_ROUTES: IRoute[] = [
  {
    path: "/admin", element: <RouteAuthMiddleware />,
    children: [
      { index: true, path: "/admin", label: "Dashboard", icon: <Icon children="dashboard"/>, element: <AdminDashboard /> },
      { path: "/admin/input", label: "Input Faktur", icon: <Icon children="input"/>, element: <AdminInputPage /> },
      { path: "/admin/report", label: "Reporting Faktur", icon: <Icon children="summarize"/>, element: <AdminReportPage /> },
      { path: "/admin/master", label: "Master Faktur", icon: <Icon children="post_add"/>, element: <AdminMasterPage /> },
      { path: "/admin/edit/:idFaktur", label: "Edit Faktur", icon: <Icon children="edit"/>, hideInNav: true, element: <AdminEditPage /> },
      // {
      //   label: "Master", icon: <Icon children="post_add"/>,
      //   children: [
      //     { index: true, path: "/admin/master/absensi", label: "Absensi", icon: <Icon children="how_to_reg"/>, element: <AdminMasterAbsensi /> },
      //     { path: "/admin/master/absensi/c", hideInNav: true, element: <AdminMasterAbsensiCreate /> },
      //     { path: "/admin/master/absensi/d/:id_absensi", hideInNav: true, element: <AdminMasterAbsensiDetail /> },
      //     { path: "/admin/master/pbc", label: "Materi PBC", icon: <Icon children="collections_bookmark"/>, element: <AdminMasterRenungan /> },
      //     { path: "/admin/master/pbc/c", hideInNav: true, element: <AdminMasterRenunganCreate /> },
      //     { path: "/admin/master/pbc/d/:id_renungan", hideInNav: true, element: <AdminMasterRenunganDetail /> },
      //     { path: "/admin/master/pbc/p/:id_renungan", hideInNav: true, element: <MainRenunganDetail /> },
      //     { path: "/admin/master/pdc", label: "PDC", icon: <Icon children="groups"/>, element: <AdminMasterPdc /> },
      //     { path: "/admin/master/pdc/c", hideInNav: true, element: <AdminMasterPdcCreate /> },
      //     { path: "/admin/master/pdc/d/:id_pdc", hideInNav: true, element: <AdminMasterPdcDetail /> },
      //     { path: "/admin/master/jemaat", label: "Jemaat", icon: <Icon children="account_box"/>, element: <AdminMasterJemaat /> },
      //     { path: "/admin/master/jemaat/c", hideInNav: true, element: <AdminMasterJemaatCreate /> },
      //     { path: "/admin/master/jemaat/d/:id_jemaat", hideInNav: true, element: <AdminMasterJemaatDetail /> },
      //     { path: "/admin/master/feedback-subjects", label: "Subjek Feedback", icon: <Icon children="view_list"/>, element: <AdminMasterFeedbackSubject /> },
      //     { path: "/admin/master/feedback-subjects/c", hideInNav: true, element: <AdminMasterFeedbackSubjectCreate /> },
      //     { path: "/admin/master/feedback-subjects/d/:id_feedbackSubject", hideInNav: true, element: <AdminMasterFeedbackSubjectDetail /> },
      //     { path: "/admin/master/warta", label: "e-Warta", icon: <Icon children="campaign"/>, element: <AdminMasterWarta /> },
      //     { path: "/admin/master/warta/c", hideInNav: true, element: <AdminMasterWartaCreate /> },
      //     { path: "/admin/master/warta/d/:id_warta", hideInNav: true, element: <AdminMasterWartaDetail /> },
      //     // { path: "/admin/master/announcement", label: "Pengumuman", icon: <Icon children="announcement"/>, element: <AdminManageAnnouncement /> },
      //     // { path: "/admin/master/announcement/c", hideInNav: true, element: <AdminManageAnnouncementCreate /> },
      //     // { path: "/admin/master/announcement/d/:id_pengumuman", hideInNav: true, element: <AdminManageAnnouncementDetail /> },
      //     // { path: "/admin/master/tags", label: "Master Tags", icon: <Icon children="tag"/>, element: <AdminMasterTags /> },
      //   ]
      // },
    ],
  },
]

export const ADDITION_ROUTES: IRoute[] = [
  { path: "/404", element: <Page404 />, hideInNav: true },
  // { path: "/reactpdftest", element: <ReactPDFTest />, hideInNav: true },
  { path: "*", element: <Page404 />, hideInNav: true },
]

export const ALL_ROUTES: IRoute[] = [
  ...PUBLIC_ROUTES,
  ...ADMIN_ROUTES,
  ...ADDITION_ROUTES,
]