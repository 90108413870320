import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

// import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';

import { GridRowSelectionModel } from "@mui/x-data-grid"

import Faktur from "../backend/models/Faktur"
import { useDialog } from "../hooks/dialogHook";
import MyNumberFormat from "../utils/numberFormater";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface IProps {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  rowSelectionModel: GridRowSelectionModel
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>
  fetchedData: Faktur[]
}

export default function ReportPageDppPaymentSection(props: IProps) {
  const { isLoading, setIsLoading } = props;
  const { rowSelectionModel, setRowSelectionModel } = props;
  const { fetchedData } = props;
  const navigate = useNavigate();
  const { pushAlert, pushConfirm } = useDialog();
  const [items, setItems] = useState<Faktur[]>();
  const [summaryOpen, setSummaryOpen] = useState<boolean>(true);
  const [totalOpen, setTotalOpen] = useState<boolean>(true);

  async function handleConfirmPayment() {
    setIsLoading(true)
    await Faktur.setPaid(rowSelectionModel.map(item=>String(item)))
    .then(res => {
      // console.log(res)
      pushConfirm({
        title: "Pembayaran terkonfirmasi!", content: "Apakah anda ingin meninggalkan halaman ini?",
        agreeBtnText: "YA", onAgreeBtnClick: () => navigate("/admin"),
        cancelBtnText: "TIDAK", onCancelBtnClick: () => navigate(0),
      })
    })
    .catch(err => {
      console.log(err)
      pushAlert({
        title: "Pembayaran gagal dikonfirmasi!", content: String(err.message)
      })
    })
    .finally(() => { setIsLoading(false); })
  }

  useEffect(() => {
    let tempItems: Faktur[] = [];
    rowSelectionModel.map((value) => {
      let tempFaktur = fetchedData.find((f) => (f.id === value));
      if (tempFaktur!==undefined && tempFaktur!==null) {
        tempItems.push(tempFaktur);
      }
    });
    setItems(tempItems);
  }, [rowSelectionModel, fetchedData])

  return (
    <Box>
      <Accordion expanded={summaryOpen} onChange={() => setSummaryOpen((oldValue) => !oldValue)}>
        <AccordionSummary>
          <Typography>{"Summary"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
            { items?.map((item, index) => {
              function removeItem() {
                pushConfirm({
                  title: "Hapus Faktur", content: "Apakah anda yakin untuk menghapus faktur dari pembayaran?",
                  agreeBtnText: "YA, SAYA YAKIN",
                  onAgreeBtnClick: async () => {
                    setRowSelectionModel(rowSelectionModel.filter((row)=>row!==item.id))
                  },
                })
              }

              return (
                <Stack
                  key={`partials-report-page-dpp-payment-item-${index}`}
                  direction="row" spacing={1}
                  alignItems={"start"} justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography>{`${index+1}. ${item.code} - ${item.companyName} (Rp ${item.dpp.toLocaleString()})`}</Typography>
                  <IconButton color="primary" size="small" onClick={removeItem}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              )
            }) }
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={totalOpen} onChange={() => setTotalOpen((oldValue) => !oldValue)}>
        <AccordionSummary>
          <Typography>{"Konfirmasi Pembayaran"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"justify"}>
            {"Anda akan melakukan pembayaran dengan nominal "}
            <Typography component="span" variant="h6">
              <MyNumberFormat value={items?.reduce((total, next) => Number(total + next.dpp), 0)} />
            </Typography>
            {". Apakah anda yakin?"}
          </Typography>
          <Stack direction="row" justifyContent={"end"} sx={{ width: "100%" }}>
            <Button
              variant="contained" color="success"
              sx={{ borderRadius: 0 }}
              onClick={handleConfirmPayment}
            >
              {"Konfirmasi"}
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}