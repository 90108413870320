// Number Formater with default configs values
import { NumericFormat } from 'react-number-format';

interface IProps {
  displayType?: "text" | "input"
  thousandSeparator?: string
  decimalSeparator?: string
  prefix?: string
  suffix?: string
  value?: number
}

export default function MyNumberFormat(props: IProps) {
  const { displayType = "text" } = props;
  const { thousandSeparator = "." } = props;
  const { decimalSeparator = "," } = props;
  const { prefix = "Rp " } = props;
  const { suffix } = props;
  const { value = 0 } = props;

  return (
    <NumericFormat
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={prefix}
      suffix={suffix}
      value={value}
    />
  )
}
