import { Fragment } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AuthProvider } from './hooks/auth';
import { DialogProvider } from './hooks/dialogHook';
import { MuiTheme } from './themes/mui/_Theme';
import MyRoutes from './routes/MyRoutes';
// import '../App.css';

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={MuiTheme}>
          <AuthProvider>
            <DialogProvider>
              <MyRoutes />
            </DialogProvider>
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Fragment>
  );
}

export default App;
